/**
 * Font loader
 *
 * @author John Noel <john.noel@rckt.co.uk>
 * @package TateHindle
 */

var WebFontConfig = {
    custom: {
        families: [ 'Sinkin Sans:n3,n4,n5,n7', 'Rockwell:n4' ],
        urls: [ '/assets/templates/v1/css/fonts.css' ]
    },

    // fudges for events
    active: function() {
        if (typeof webfontsActive != 'undefined') {
            webfontsActive();
        }
    },

    inactive: function() {
        if (typeof webfontsInactive != 'undefined') {
            webfontsInactive();
        }
    }
};

(function() {
    var wf = document.createElement('script');
    wf.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
    wf.type = 'text/javascript';
    wf.async = 'true';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(wf, s);
})();
